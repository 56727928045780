var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.companyId || _vm.clusterId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch && _vm.$refs.confirmDelete)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Bildirim Şablonları(SMS-Mobil)","icon":"mdi-cellphone-settings","add-route":_vm.can('edit-notification-template')
              ? { name: 'communication.notification-templates.create' }
              : null,"hide-edit":!_vm.can('edit-notification-template'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query,"show-delete":_vm.can('delete-notification-template'),"delete-enabled":_vm.selectedItems.length > 0,"delete-tooltip":"En az bir satır seçmelisiniz"},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)},"click:delete":function($event){return _vm.$refs.confirmDelete.show(
              'Seçili şablonları silmek istediğinizden emin misiniz?'
            )}}},'rs-table-title-bar',_vm.titleBarAttrs,false)):_vm._e(),_c('rs-confirm',{ref:"confirmDelete",attrs:{"loading":_vm.isLoading},on:{"confirmed":_vm.handleDeleteSubmitClick,"cancelled":function($event){return _vm.$refs.confirmDelete.hide()}}})]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'communication.notification-templates.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.is_system_copied",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.description",fn:function({ value }){return [_c('rs-table-cell-string',{attrs:{"limit":50,"value":value}})]}}],null,false,994717936),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }